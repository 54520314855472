<template>
  <div class="help-chat">
    <tooltip
      content="¿Necesitas ayuda?"
      position="left"
      :class="{ disabled: isChatOpen }"
    >
      <button
        type="button"
        :class="['button-main', { active: isChatOpen }]"
        data-test-id="startSupportButton"
        @click="openChat"
      >
        <HelpIcon class="icon" />
      </button>
    </tooltip>
    <template v-if="isChatOpen">
      <a
        role="button"
        :href="whatsAppChatUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="button-secondary button-whatsapp"
        data-test-id="supportWhatsAppLink"
        @click="closeChat"
      >
        <WhatsAppIcon class="icon" />
      </a>
      <a
        role="button"
        :href="telegramChatUrl"
        target="_blank"
        rel="noopener noreferrer"
        class="button-secondary button-telegram"
        data-test-id="supportTelegramLink"
        @click="closeChat"
      >
        <TelegramIcon class="icon" />
      </a>
      <button
        type="button"
        class="button-secondary button-close"
        data-test-id="supportCloseButton"
        @click="closeChat"
      >
        <CloseIcon class="icon" fill="white" />
      </button>
    </template>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import CloseIcon from '@/assets/images/close.svg';
import HelpIcon from '@/assets/images/help.svg';
import TelegramIcon from '@/assets/images/telegram.svg';
import Tooltip from '@/components/Tooltip.vue';
import WhatsAppIcon from '@/assets/images/whatsapp.svg';

const whatsAppChatUrl = import.meta.env.VITE_CHAT_HELP_WHATSAPP_URL;
const telegramChatUrl = import.meta.env.VITE_CHAT_HELP_TELEGRAM_URL;
const isChatOpen = ref(false);

const openChat = () => {
  isChatOpen.value = true;
};

const closeChat = () => {
  isChatOpen.value = false;
};

const handleOutsideClick = (e) => {
  if (!e.target.closest('.help-chat')) {
    isChatOpen.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleOutsideClick);
});

onUnmounted(() => {
  document.removeEventListener('click', handleOutsideClick);
});
</script>

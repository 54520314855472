<template>
  <loading
    v-model:active="isLoading"
    data-test-id="loaderView"
    :can-cancel="false"
    :is-full-page="true"
    :lock-scroll="true"
    :enforce-focus="false"
  >
    <template #default>
      <Vue3Lottie :animation-data="animationData" :width="322" />
    </template>
  </loading>
</template>

<script setup>
import { watch, ref, onMounted } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';
import { useUserStore } from '@/stores/user';
import Loading from 'vue-loading-overlay';
import DefaultAnimationData from '@/assets/animations/loader_short.json';

const store = useUserStore();
const emits = defineEmits(['finish']);
const props = defineProps({
  loading: {
    require: true,
    type: Boolean,
  },
  type: {
    type: String,
    default: 'bars',
  },
  animationData: {
    type: Object,
    default: DefaultAnimationData,
  },
  time: {
    type: Number,
    default: 3400,
  },
});

const isLoading = ref(false);
let intervalLoader = null;

const activateIntervalLoader = () => {
  if (intervalLoader) {
    clearInterval(intervalLoader);
  }

  intervalLoader = setInterval(() => {
    if (!store.isLoading) {
      clearInterval(intervalLoader);
      isLoading.value = false;
      emits('finish');
    }
  }, props.time);
};

watch(
  () => store.isLoading,
  (newValue) => {
    if (newValue) {
      isLoading.value = true;
      activateIntervalLoader();
    }
  }
);

onMounted(() => {
  if (store.isLoading) {
    isLoading.value = true;
    activateIntervalLoader();
  }
});
</script>

<template>
  <div>
    <div class="verified__container">
      <div class="verified__container__icon-container">
        <CheckCircle />
      </div>
      <p>
        {{ $t('register.form.linked-account') }}
      </p>

      <button type="button" @click="next">
        {{ $t('register.config-billing.next') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import router from '@/router';
import { useUserStore } from '@/stores/user';
import CheckCircle from '@/assets/images/check-circle.svg';

const store = useUserStore();

const next = () => {
  store.setStepCreatingAccount(3);
  router.push({ name: 'ConfigSpace' });
};
</script>

<style lang="scss" scoped>
@import '~styles/views/verify-account';
</style>

<template>
  <div class="page-not-found">
    <div class="content">
      <div class="exclamation">
        <div class="rectangle"></div>
        <div class="circle"></div>
      </div>
      <h1 class="code text-title">404</h1>
      <h2 class="subtitle text-subtitle">Página no encontrada</h2>
      <router-link to="/" class="button">Inicio</router-link>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.collapse = window.screen.width < 1024;
  },
};
</script>

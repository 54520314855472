<template>
  <div>
    <div class="verified__container">
      <div class="verified__container__icon-container">
        <CheckCircle />
      </div>
      <p>
        {{ $t('register.team-created.description') }}
      </p>

      <button type="button" @click="login">
        {{ $t('register.team-created.sign-in') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import router from '@/router';
import { useUserStore } from '@/stores/user';
import CheckCircle from '@/assets/images/check-circle.svg';

const store = useUserStore();

const login = async () => {
  store.resetData();
  router.push('/');
};
</script>

<style lang="scss" scoped>
@import '~styles/views/verify-account';
</style>

<template>
  <div>
    <AccountStepper :current-step="1" />

    <div class="verified__container">
      <div class="verified__container__icon-container">
        <CheckCircle />
      </div>
      <p v-html="$t('register.verify-account.verify')" />

      <button
        type="button"
        @click="nextStep"
        v-html="$t('register.verify-account.next')"
      />
    </div>
  </div>
</template>

<script setup>
import CheckCircle from '@/assets/images/check-circle.svg';
import router from '@/router';
import AccountStepper from '@/components/account/AccountStepper.vue';
import { useUserStore } from '@/stores/user';
import { onMounted } from 'vue';

const store = useUserStore();

const nextStep = async () => {
  store.setStepCreatingAccount(3);
  router.push({ name: 'ConfigSpace' });
};

onMounted(() => {
  const { data, refresh } = router.currentRoute.value.query;
  store.resetData();
  store.verify(data, refresh);

  if (store.$state.stepCreatingAccount > 2) {
    store.setStepAccountURL();
  }
});
</script>

<style lang="scss" scoped>
@import '~styles/views/verify-account';
</style>

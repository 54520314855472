<template>
  <div>
    <AccountStepper :current-step="3" />

    <div class="register__container">
      <div class="register__container-notify-top">
        <CheckCircle />
        <p>{{ $t('register.config-payment.payment-processed') }}</p>
      </div>

      <p v-html="$t('register.config-billing.description')" />

      <Form @submit="onSubmit">
        <div class="register__form">
          <p>
            <b>{{ $t('register.config-billing.billing-contact.title') }}</b>
          </p>

          <div class="register__form-group">
            <label for="email"
              >{{
                $t('register.config-billing.billing-contact.email')
              }}
              *</label
            >
            <Field
              name="email"
              rules="required|email"
              v-slot="{ errorMessage, field }"
            >
              <input
                id="email"
                data-test-id="billingEmailText"
                type="text"
                autocomplete="off"
                :class="{ error: errorMessage }"
                v-bind="field"
              />
              <transition name="fade">
                <span
                  data-test-id="emailBillingErrorLabel"
                  class="register__form-group-error"
                  v-if="errorMessage"
                  >{{ errorMessage }}</span
                >
              </transition>
            </Field>
          </div>

          <p>
            <b>{{ $t('register.config-billing.company-information.title') }}</b>
          </p>

          <div class="register__form-group">
            <label for="country"
              >{{
                $t('register.config-billing.company-information.country')
              }}
              *</label
            >
            <Field name="country" as="select" v-model="country" disabled>
              <option
                data-test-id="countryBillingSelect"
                v-for="(country, index) in countries"
                :value="country.code3"
                :key="`country-item-${index}`"
              >
                {{ country.name }}
              </option>
            </Field>
          </div>

          <div class="register__form-grid-cols-2">
            <div class="register__form-group">
              <label for="state"
                >{{ $t('register.config-billing.company-information.state') }}
                <span v-if="country !== 'MEX'">*</span></label
              >
              <Field
                data-test-id="stateBillingLabel"
                name="state"
                as="select"
                :rules="
                  country !== 'MEX' && getStatesByCountry.length
                    ? 'required'
                    : null
                "
                :disabled="!getStatesByCountry.length"
              >
                <option
                  v-for="(state, index) in getStatesByCountry"
                  :value="state.name"
                  :key="`state-item-${index}`"
                >
                  {{ state.name }}
                </option>
              </Field>
            </div>

            <div class="register__form-group">
              <label for="zip"
                >{{
                  $t('register.config-billing.company-information.zip')
                }}
                *</label
              >
              <Field
                name="zip"
                :rules="`required|${
                  country === 'MEX' ? 'mexPostalCode' : 'usaZip'
                }`"
                v-slot="{ errorMessage, field }"
              >
                <input
                  id="zip"
                  data-test-id="zipBillingText"
                  type="text"
                  :class="{ error: errorMessage }"
                  v-bind="field"
                />
                <transition name="fade">
                  <span
                    data-test-id="zipBillingErrorLabel"
                    class="register__form-group-error"
                    v-if="errorMessage"
                    >{{ errorMessage }}</span
                  >
                </transition>
              </Field>
            </div>
          </div>

          <div class="register__form-group">
            <label for="address"
              >{{ $t('register.config-billing.company-information.address') }}
              <span v-if="country !== 'MEX'">*</span>
            </label>
            <Field
              name="address"
              :rules="country !== 'MEX' ? 'required' : null"
              v-slot="{ errorMessage, field }"
            >
              <input
                id="address"
                data-test-id="addressBillingText"
                type="text"
                :class="{ error: errorMessage }"
                v-bind="field"
              />
              <transition name="fade">
                <span
                  data-test-id="addressBillingErrorLabel"
                  class="register__form-group-error"
                  v-if="errorMessage"
                  >{{ errorMessage }}</span
                >
              </transition>
            </Field>
          </div>

          <div v-if="country !== 'MEX'" class="register__form-group">
            <label for="organization_name"
              >{{
                $t(
                  'register.config-billing.company-information.organization-name'
                )
              }}
              *</label
            >
            <Field
              name="organization_name"
              rules="required"
              v-slot="{ errorMessage, field }"
            >
              <input
                id="organization_name"
                data-test-id="organizationBillingText"
                type="text"
                :class="{ error: errorMessage }"
                v-bind="field"
              />
              <transition name="fade">
                <span
                  data-test-id="organizationBillingErrorLabel"
                  class="register__form-group-error"
                  v-if="errorMessage"
                  >{{ errorMessage }}</span
                >
              </transition>
            </Field>
          </div>

          <div class="register__form-grid-cols-2" v-if="country !== 'MEX'">
            <div class="register__form-group">
              <label for="tax_id_type"
                >{{ $t('register.config-billing.company-information.tax-id') }}
                *
                <tooltip content="Tax ID description" position="top">
                  <QuestionIcon />
                </tooltip>
              </label>
              <Field
                name="tax_id_type"
                rules="required"
                as="select"
                v-model="taxType"
              >
                <option value="EIN">EIN</option>
                <option value="SSN">Tax ID</option>
              </Field>
            </div>

            <div class="register__form-group">
              <label for="tax_id_value">&nbsp;</label>
              <Field
                name="tax_id_value"
                :rules="taxType === 'SSN' ? 'required|ssn' : 'required|ein'"
                v-slot="{ errorMessage, field }"
              >
                <input
                  id="tax_id_value"
                  type="text"
                  :class="{ error: errorMessage }"
                  v-bind="field"
                />
                <transition name="fade">
                  <span
                    class="register__form-group-error"
                    v-if="errorMessage"
                    >{{ errorMessage }}</span
                  >
                </transition>
              </Field>
            </div>
          </div>

          <div v-if="country === 'MEX'" class="register__form-group">
            <label for="social_reason"
              >{{
                $t('register.config-billing.company-information.social-reason')
              }}
              *</label
            >
            <Field
              name="social_reason"
              rules="required"
              v-slot="{ errorMessage, field }"
            >
              <input
                id="social_reason"
                data-test-id="socialReasonBillingText"
                type="text"
                :class="{ error: errorMessage }"
                v-bind="field"
              />
              <transition name="fade">
                <span
                  data-test-id="socialReasonErrorLabel"
                  class="register__form-group-error"
                  v-if="errorMessage"
                  >{{ errorMessage }}</span
                >
              </transition>
            </Field>
          </div>

          <div v-if="country === 'MEX'" class="register__form-group">
            <label for="rfc"
              >{{
                $t('register.config-billing.company-information.rfc')
              }}
              *</label
            >
            <Field
              name="rfc"
              rules="required|rfc"
              v-slot="{ errorMessage, field }"
            >
              <input
                id="rfc"
                data-test-id="rfcBillingText"
                type="text"
                :class="{ error: errorMessage }"
                v-bind="field"
              />
              <transition name="fade">
                <span
                  data-test-id="rfcErrorLabel"
                  class="register__form-group-error"
                  v-if="errorMessage"
                  >{{ errorMessage }}</span
                >
              </transition>
            </Field>
          </div>

          <div v-if="country === 'MEX'" class="register__form-group">
            <label for="tax_regime"
              >{{
                $t('register.config-billing.company-information.tax-regime')
              }}
              *</label
            >
            <Field
              name="tax_regime"
              data-test-id="regimeBillingSelect"
              rules="required"
              as="select"
            >
              <option
                v-for="(tax_regime, index) in taxRegimes"
                :value="tax_regime.value"
                :key="`tax-regime-item-${index}`"
              >
                {{ tax_regime.name }}
              </option>
            </Field>
          </div>

          <div v-if="country === 'MEX'" class="register__form-group">
            <label for="cfdi"
              >{{
                $t('register.config-billing.company-information.cfdi')
              }}
              *</label
            >
            <Field
              name="cfdi"
              data-test-id="cfdiBillingSelect"
              as="select"
              rules="required"
            >
              <option
                v-for="(cfdi, index) in cfdiUsage"
                :value="cfdi.value"
                :key="`cfdi-item-${index}`"
              >
                {{ cfdi.name }}
              </option>
            </Field>
          </div>

          <button
            type="button"
            data-test-id="billingLaterLink"
            class="skip-button"
            @click="skip"
          >
            {{ $t('register.config-billing.bill-later') }}
          </button>

          <button
            type="submit"
            data-test-id="nextBillingButton"
            :disabled="isLoading"
          >
            {{ $t('register.config-billing.next') }}
          </button>

          <p
            data-test-id="billingErrorLabel"
            v-if="errorMsg.length"
            class="register__form-default-error"
          >
            {{ errorMsg }}
          </p>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Field, Form } from 'vee-validate';
import { useUserStore } from '@/stores/user';
import AccountStepper from '@/components/account/AccountStepper.vue';
import router from '@/router';
import CheckCircle from '@/assets/images/check-circle.svg';
import Tooltip from '@/components/Tooltip.vue';
import QuestionIcon from '@/assets/images/question.svg';
import { datadogRum } from '@datadog/browser-rum';

const store = useUserStore();
const { t } = useI18n();
const isLoading = ref(false);
const errorMsg = ref('');
const country = ref('MEX');
const taxType = ref('');
const countries = ref([]);

const taxRegimes = [
  {
    name: t('register.config-billing.tax_regimes.general_legal_person'),
    value: 'general_legal_person',
  },
  {
    name: t('register.config-billing.tax_regimes.legal_entities_non_profit'),
    value: 'legal_entities_non_profit',
  },
  {
    name: t('register.config-billing.tax_regimes.resident_abroad'),
    value: 'resident_abroad',
  },
  {
    name: t(
      'register.config-billing.tax_regimes.production_cooperative_societies'
    ),
    value: 'production_cooperative_societies',
  },
  {
    name: t('register.config-billing.tax_regimes.agricultural_livestock'),
    value: 'agricultural_livestock',
  },
  {
    name: t('register.config-billing.tax_regimes.optional_group_of_companies'),
    value: 'optional_group_of_companies',
  },
  {
    name: t('register.config-billing.tax_regimes.coordinated'),
    value: 'coordinated',
  },
  {
    name: t('register.config-billing.tax_regimes.simplified_trust'),
    value: 'simplified_trust',
  },
  {
    name: t('register.config-billing.tax_regimes.wages_salaries_income'),
    value: 'wages_salaries_income',
  },
  { name: t('register.config-billing.tax_regimes.lease'), value: 'lease' },
  {
    name: t(
      'register.config-billing.tax_regimes.property_disposal_acquisition'
    ),
    value: 'property_disposal_acquisition',
  },
  {
    name: t('register.config-billing.tax_regimes.other_income'),
    value: 'other_income',
  },
  {
    name: t('register.config-billing.tax_regimes.dividend_income'),
    value: 'divident_income',
  },
  {
    name: t(
      'register.config-billing.tax_regimes.individual_business_professional'
    ),
    value: 'individual_business_professional',
  },
  {
    name: t('register.config-billing.tax_regimes.interest_income'),
    value: 'interest_income',
  },
  {
    name: t('register.config-billing.tax_regimes.income_obtaining_price'),
    value: 'income_obtaining_price',
  },
  {
    name: t('register.config-billing.tax_regimes.no_tax_obligation'),
    value: 'no_tax_obligation',
  },
  {
    name: t('register.config-billing.tax_regimes.tax_incorporation'),
    value: 'tax_incorporation',
  },
  {
    name: t(
      'register.config-billing.tax_regimes.income_through_technology_platform'
    ),
    value: 'income_through_technology_platform',
  },
];

const cfdiUsage = [
  { name: 'Adquisición de Mercancía', value: 'acquisition_of_merchandise' },
  {
    name: 'Devolución, Descuento, Bonificación',
    value: 'return_discount_bonus',
  },
  { name: 'Gastos Generales', value: 'general_expense' },
  { name: 'Edificios', value: 'buildings' },
  {
    name: 'Mobiliario y Equipo de Oficina',
    value: 'furniture_office_equipment',
  },
  { name: 'Equipo de Transporte', value: 'transport_equipment' },
  { name: 'Equipo de Computación', value: 'computer_equipment' },
  { name: 'Herramientas y Moldes', value: 'dye_molds_tools' },
  { name: 'Comunicación Telefónica', value: 'telephone_communication' },
  { name: 'Comunicación Satelital', value: 'satellite_communication' },
  { name: 'Otro Equipo y Maquinaria', value: 'other_machinery_equipment' },
  { name: 'Gastos Hospitalarios', value: 'hospital_expense' },
  {
    name: 'Gastos Médicos por Incapacidad',
    value: 'medical_expense_disability',
  },
  { name: 'Gastos Funerarios', value: 'funeral_expense' },
  { name: 'Donación', value: 'donation' },
  {
    name: 'Intereses de Préstamos Hipotecarios',
    value: 'interest_mortage_loans',
  },
  { name: 'Contribución al SAR', value: 'contribution_sar' },
  {
    name: 'Prima de Seguro Médico',
    value: 'medical_expense_insurance_premium',
  },
  {
    name: 'Gastos de Transporte Escolar',
    value: 'school_transportation_expense',
  },
  { name: 'Depósito en Cuenta de Ahorro', value: 'deposit_saving_account' },
  {
    name: 'Pago de Servicios Educativos',
    value: 'payment_educational_service',
  },
  { name: 'Sin Efecto Fiscal', value: 'no_tax_effect' },
  { name: 'Pago', value: 'payment' },
  { name: 'Nómina', value: 'payroll' },
];

const getStatesByCountry = computed(
  () =>
    countries.value.find((item) => country.value === item.code3)?.states ?? []
);

const onSubmit = async (values) => {
  errorMsg.value = '';
  isLoading.value = true;

  let payload = {};

  try {
    if (country.value === 'MEX') {
      payload = {
        email: values.email,
        company_name: values.social_reason,
        currency_code: store.$state.planSelected.currencyCode,
        country: values.country,
        tax_regime: values.tax_regime,
        tax_reg_no: values.rfc,
        state: values.state,
        address: values.address,
        zip_code: values.zip,
      };
    } else if (country.value === 'USA') {
      payload = {
        email: values.email,
        company_name: values.organization_name,
        currency_code: store.$state.planSelected.currencyCode,
        country: values.country,
        state: values.state,
        address: values.address,
        zip_code: values.zip,
        tax_id_type: values.tax_id_type,
        tax_id_value: values.tax_id_value,
      };
    }

    await store.configBilling(payload);

    store.setStepCreatingAccount(7);
    router.push({ name: 'AccountInvoiced' });
  } catch (error) {
    if (error.response?.data?.messages) {
      errorMsg.value = error.response.data.messages;
    } else {
      errorMsg.value = t('default-error');
      datadogRum.addError(error);
    }

    isLoading.value = false;
  }
};

const skip = () => {
  store.setStepCreatingAccount(7);
  router.push({ name: 'ConfigInvite' });
};

const loadCountries = async () => {
  try {
    const response = await fetch('/json/countries_and_states.json');
    countries.value = await response.json();
  } catch (error) {
    console.error('Error al cargar el archivo JSON:', error);
    datadogRum.addError(error);
  }
};

onMounted(async () => {
  if (store.$state.stepCreatingAccount !== 5) {
    store.setStepAccountURL();
  }

  await loadCountries();
  country.value = store.$state.countrySelected || 'MEX';
});
</script>

<style lang="scss" scoped>
@import '~styles/views/create-account';
</style>

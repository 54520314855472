<script setup>
import BG1 from '@/assets/images/backgrounds/bg.svg';
import BG2 from '@/assets/images/backgrounds/bg2.svg';
import BG3 from '@/assets/images/backgrounds/bg3.svg';
import BG4 from '@/assets/images/backgrounds/bg4.svg';
import BG5 from '@/assets/images/backgrounds/bg5.svg';
import { computed, onBeforeUnmount, onMounted } from 'vue';

const allBackgrounds = computed(() => [BG1, BG2, BG3, BG4, BG5]);
const background = computed(() => {
  const index = Math.floor(Math.random() * allBackgrounds.value.length);
  return allBackgrounds.value[index];
});

onMounted(() => {
  document.body.classList.add('overflow-hidden');
});

onBeforeUnmount(() => {
  document.body.classList.remove('overflow-hidden');
});
</script>

<template>
  <transition name="fade">
    <span>
      <component :is="background" class="background" />
      <slot />
    </span>
  </transition>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/views/_login.scss';
</style>

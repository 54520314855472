<template>
  <div class="stepper">
    <ul>
      <li
        :class="{ active: currentStep === index + 1 }"
        v-for="(step, index) in stepList"
        :key="`step-${index}`"
      >
        <span v-html="step.name" />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const stepList = [
  {
    name: t('register.stepper.create-account'),
  },
  {
    name: t('register.stepper.config-workspace'),
  },
  {
    name: t('register.stepper.payment'),
  },
  {
    name: t('register.stepper.invite-team'),
  },
];

defineProps({
  currentStep: {
    type: Number,
    required: true,
  },
});
</script>

<style lang="scss" scoped>
@import '~styles/shared/stepper';
</style>

<template>
  <div>
    <div class="verified__container">
      <div class="verified__container__icon-container">
        <CheckCircle />
      </div>
      <p>
        {{ $t('register.account-invoiced.description') }}
      </p>

      <button type="button" @click="next">
        {{ $t('register.account-invoiced.next') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import CheckCircle from '@/assets/images/check-circle.svg';
import router from '@/router';

const next = async () => {
  router.push({ name: 'ConfigInvite' });
};
</script>

<style lang="scss" scoped>
@import '~styles/views/verify-account';
</style>

<template>
  <div>
    <AccountStepper :current-step="4" />

    <div class="register__container invite-team">
      <div>
        <p v-html="$t('register.invite-team.description')" />
        <p class="agent-description">
          {{ $t('register.invite-team.agent_required_description') }}
        </p>
      </div>

      <Form @submit="onSubmit">
        <div class="register__form">
          <div
            v-for="(user, index) in teamList.slice().reverse()"
            :key="`user-team-list-${index}`"
            class="register__form-grid-cols-invite"
          >
            <div class="register__form-group">
              <Field
                label="name"
                :name="`team[${index}]name`"
                rules="required"
                v-model="user.name"
                v-slot="{ errorMessage, field }"
              >
                <input
                  :placeholder="$t('register.invite-team.form.name')"
                  data-test-id="inviteNameText"
                  autocomplete="off"
                  :class="{ error: errorMessage }"
                  v-bind="field"
                />
                <transition name="fade">
                  <span
                    data-test-id="inviteNameErrorLabel"
                    class="register__form-group-error"
                    v-if="errorMessage"
                    >{{ errorMessage }}</span
                  >
                </transition>
              </Field>
            </div>

            <div class="register__form-group">
              <Field
                label="email"
                :name="`team[${index}]email`"
                rules="required|email"
                v-model="user.email"
                v-slot="{ errorMessage, field }"
              >
                <input
                  type="email"
                  data-test-id="inviteEmailText"
                  :placeholder="$t('register.invite-team.form.email')"
                  autocomplete="off"
                  :class="{ error: errorMessage }"
                  v-bind="field"
                />
                <transition name="fade">
                  <span
                    data-test-id="inviteEmailErrorLabel"
                    class="register__form-group-error"
                    v-if="errorMessage"
                    >{{ errorMessage }}</span
                  >
                </transition>
              </Field>
            </div>

            <div class="register__form-group">
              <Field
                label="role"
                data-test-id="roleInviteSelect"
                :name="`team[${index}]role`"
                as="select"
                rules="required"
                v-model="user.role"
              >
                <option value="" disabled selected>
                  {{ $t('register.invite-team.form.role') }}
                </option>
                <option
                  v-for="(role, index) in roles"
                  :value="JSON.stringify(role)"
                  :key="`role-item-${index}`"
                  :disabled="
                    (role.name === SUPER_ROLE || role.name === ADMIN_ROLE) &&
                    isMaxLicensesExhausted &&
                    getRoleName(user.role) !== SUPER_ROLE &&
                    getRoleName(user.role) !== ADMIN_ROLE
                  "
                >
                  {{ role.display_name }}
                </option>
              </Field>
              <ErrorMessage :name="`team[${index}]role`" />
            </div>
            <span data-test-id="infoToolTip" class="register__form-icon">
              <tooltip :content="getRoleDescription(user.role)" position="top">
                <QuestionIcon />
              </tooltip>
            </span>

            <div
              v-if="user.id === 0"
              data-test-id="addUserButton"
              class="invite-button bg-blue"
              @click="addField"
            >
              <Icon icon="mdi:add" class="add-color icon-size" />
            </div>
            <div
              v-else
              data-test-id="deleteRowButton"
              class="invite-button bg-red"
              @click="removeField(user.id)"
            >
              <Icon icon="mdi:trash-outline" class="add-color icon-size" />
            </div>
          </div>
        </div>

        <div class="register__button-invite">
          <div class="container-message-button">
            <p v-if="!agentRequiredMessage" class="agent-error-description">
              {{ $t('register.invite-team.agent_error_description') }}
            </p>
            <button
              data-test-id="inviteSubmitButton"
              type="submit"
              :disabled="isLoading"
            >
              {{ $t('register.invite-team.form.invite') }}
            </button>
          </div>

          <ul v-if="Array.isArray(errorMsg)">
            <li
              v-for="(error, index) in errorMsg"
              :key="`error-msg-${index}`"
              class="register__form-default-error"
            >
              {{ error.message }}
            </li>
          </ul>

          <p
            v-if="errorMsg instanceof String || typeof errorMsg === 'string'"
            class="register__form-default-error text-center"
          >
            {{ errorMsg }}
          </p>
        </div>
      </Form>
    </div>
  </div>
  <OverlayLoader
    :animation-data="animationData"
    :time="5500"
    @finish="redirect"
  />
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { useUserStore } from '@/stores/user';
import AccountStepper from '@/components/account/AccountStepper.vue';
import { getRoles } from '@/services/user';
import QuestionIcon from '@/assets/images/question.svg';
import { ADMIN_ROLE, SUPER_ROLE } from '@/consts/roles';
import Tooltip from '@/components/Tooltip.vue';
import router from '@/router';
import { datadogRum } from '@datadog/browser-rum';
import { Icon } from '@iconify/vue';
import OverlayLoader from '@/components/OverlayLoader.vue';
import animationData from '@/assets/animations/loader_setup.json';

const store = useUserStore();
const { t } = useI18n();
const isLoading = ref(false);
const errorMsg = ref('');
const teamList = ref([{ id: 0, name: '', email: '', role: null }]);
const roles = ref([]);
const maxLicenses = ref(2);
const company = ref(null);
const agentRequiredMessage = ref(true);

const loadRoles = async () => {
  try {
    const roleList = await getRoles();
    roles.value = roleList.data;
  } catch (error) {
    console.error(error);
    datadogRum.addError(error);
  }
};

const addField = () => {
  teamList.value.push({ id: teamList.value.length+1, name: '', email: '', role: null });
};

const removeField = (idUserTeamList) => {
  if (idUserTeamList === 0) {
    return;
  }
  teamList.value.splice(teamList.value.findIndex(x => x.id === idUserTeamList), 1);
  const roleData = teamList.value.map(member => member.role);
  checkAgentSelect(roleData)
};

const setupCompanies = async  () => {
  try {
    await store.companiesSetup({
      companyId: company.value._id,
    });
  } catch (error) {
    datadogRum.addError(error);
  }
};

const redirect = () => {
  if (errorMsg.value  !== '') {
    return;
  }
  router.push({ name: 'AccountInvited' });
}

const onSubmit = async ({ team }) => {
  if (!agentRequiredMessage.value) {
    agentRequiredMessage.value = false;
    return;
  }
  errorMsg.value = '';
  isLoading.value = true;
  store.activateLoading(true);

  try {
    await store.configInvite({
      company: company.value._id,
      workspace: company.value.workspace._id,
      data: team.map((user) => {
        const role = JSON.parse(user.role);

        return {
          ...user,
          role: role._id,
        };
      }),
    });

    await setupCompanies();
  } catch (error) {
    console.error(error);

    if (error.response?.data?.errors?.length) {
      errorMsg.value = error.response.data.errors;
    } else if (error.response?.data?.message) {
      errorMsg.value = error.response.data.message;
    } else {
      errorMsg.value = t('default-error');
    }
    datadogRum.addError(error);
  } finally {
    isLoading.value = false;
    store.activateLoading(false);
  }
};

const isMaxLicensesExhausted = computed(
  () =>
    teamList.value.reduce((accum, user) => {
      if (!user.role) {
        return accum;
      }

      const role = JSON.parse(user.role);

      return accum + (role?.name === SUPER_ROLE || role.name === ADMIN_ROLE);
    }, 0) >= maxLicenses.value
);

const getRoleName = (roleJSON) => {
  if (!roleJSON) {
    return null;
  }

  const role = JSON.parse(roleJSON);

  return role.name;
};

const getRoleDescription = (roleJSON) => {
  if (!roleJSON) {
    return 'Select a role';
  }

  const role = JSON.parse(roleJSON);

  return role.description;
};

const checkAgentSelect = (roleJSON) => {
  agentRequiredMessage.value = roleJSON.some((member) => {
    if (member === null) return;
    return JSON.parse(member).name === 'agent';
  });
};

onMounted(() => {
  loadRoles();

  if (store.$state.stepCreatingAccount !== 7) {
    store.setStepAccountURL();
    return;
  }

  const tier = store.$state.planSelected;
  company.value = store.$state.company;
  maxLicenses.value = (tier.licenses || 2) - 2;
  store.refreshToken(store.refresh);
});

watch(
  () => teamList.value.map((member) => member.role),
  (newRoles, oldRoles) => {
    if (newRoles.some((role, index) => role !== oldRoles[index])) {
      checkAgentSelect(newRoles);
    }
  },
  { deep: true }
);
</script>

<style lang="scss" scoped>
@import '~styles/views/create-account';
</style>

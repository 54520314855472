<template>
  <div>
    <div class="verified__container">
      <div class="verified__container__icon-container">
        <CheckCircle />
      </div>
      <p>
        {{ $t('register.team-invited.description') }}
      </p>
      <p class="description">
        {{ $t('register.team-invited.sub-description') }}
      </p>

      <button type="button" @click="login">
        {{ $t('register.team-invited.sign-in') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from '@/stores/user';
import CheckCircle from '@/assets/images/check-circle.svg';

const store = useUserStore();

const login = async () => {
  const { token } = store.$state;
  store.clearStepsCreatingAccount();
  window.location.href = `${import.meta.env.VITE_ADMIN_BASE_URL}?data=${token}`;
};
</script>

<style lang="scss" scoped>
@import '~styles/views/verify-account';
</style>

<template>
  <div class="flex">
    <input
      class="input w-full"
      ref="inputRef"
      :value="modelValue"
      @input="updateValue"
      v-bind="$attrs"
    />
    <span
      v-if="hasError"
      data-test-id="couponErrorIcon"
      class="input-error flex items-center justify-center"
    >
      <ErrorIcon />
    </span>
  </div>
</template>

<script setup>
import ErrorIcon from '@/assets/images/error.svg';

defineProps({
  modelValue: {
    type: String,
    default: '',
    required: false,
  },
  hasError: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const updateValue = (event) => {
  emit('update:modelValue', event.target.value);
};
</script>

<template>
  <background>
    <div class="page">
      <logo v-show="showLogo" />
      <transition name="fade">
        <div class="login" v-show="show" style="animation-duration: 0.8s">
          <div v-if="done" class="success_container">
            <div class="login__success">
              <span class="login__success-text white h2">{{
                $t('login.change-password-success')
              }}</span>
            </div>
            <button
              type="button"
              class="login__button"
              @click="() => router.push({ name: 'Home' })"
            >
              {{ $t('Iniciar Sesión') }}
            </button>
          </div>
          <Form v-else @submit="onSubmit" :validation-schema="schema">
            <div class="login-form">
              <span class="login__recover">{{
                $t('login.reset-password')
              }}</span>
              <p class="login__subtitle">
                Genera una nueva contraseña para acceder al sistema
              </p>
              <div class="login__recover-group">
                <Field
                  name="password"
                  class="login__input-container"
                  v-slot="{ field, errors }"
                >
                  <label class="login__input-label">{{
                    $t('register.form.password')
                  }}</label>
                  <div class="login__input-box">
                    <lock class="login__icon" />
                    <input
                      id="password"
                      type="password"
                      class="login__input"
                      :class="errors[0] && 'error'"
                      v-bind="field"
                    />
                  </div>
                  <transition name="fade">
                    <span v-if="errors[0]" class="login__input-error">{{
                      errors[0]
                    }}</span>
                  </transition>
                  <transition name="fade">
                    <span v-if="errorMsg" class="login__input-error">{{
                      errorMsg
                    }}</span>
                  </transition>
                </Field>
                <br />
                <br />
                <Field
                  name="passwordConfirmation"
                  class="login__input-container"
                  v-slot="{ field, errors }"
                >
                  <label class="login__input-label">{{
                    $t('register.form.confirm-password')
                  }}</label>
                  <div class="login__input-box">
                    <lock class="login__icon" />
                    <input
                      id="passwordConfirmation"
                      type="password"
                      class="login__input"
                      :class="errors[0] && 'error'"
                      v-bind="field"
                    />
                  </div>
                  <transition name="fade">
                    <span v-if="errors[0]" class="login__input-error">{{
                      errors[0]
                    }}</span>
                  </transition>
                  <transition name="fade">
                    <span v-if="errorMsg" class="login__input-error">{{
                      errorMsg
                    }}</span>
                  </transition>
                </Field>
              </div>

              <button type="submit" class="login__button" :disabled="isLoading">
                {{ $t('login.change-password') }}
              </button>

              <p v-if="errorMsg.length" class="register__form-default-error">
                {{ errorMsg }}
              </p>
            </div>
          </Form>
        </div>
      </transition>
    </div>
  </background>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import router from '@/router';
import { Field, Form } from 'vee-validate';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import Lock from '@/assets/images/lock.svg';
import { datadogRum } from '@datadog/browser-rum';
import Background from './Background.vue';
import Logo from './Logo.vue';

const store = useUserStore();
const { t } = useI18n();
const isLoading = ref(false);
const errorMsg = ref('');
const userId = ref('');
const name = ref('');
const show = ref(true);
const showLogo = ref(true);
const done = ref(false);

const schema = {
  password: 'required|min:8|minUppercase:1|minLowercase:1|minDigits:1',
  passwordConfirmation: 'required|confirmed:@password',
};

onMounted(() => {
  getToken();
  setTimeout(() => {
    show.value = true;
    showLogo.value = true;
  }, 300);
});

const onSubmit = async (values) => {
  isLoading.value = true;
  errorMsg.value = '';

  try {
    const change = await store.registerTeam(userId.value, {
      name: name.value,
      password: values.password,
    });

    if (change.status === 200) {
      store.resetData();
      done.value = true;
    }
  } catch (e) {
    console.error(e);
    datadogRum.addError(e);
    errorMsg.value = t('default-error');
  } finally {
    isLoading.value = false;
  }
};

const getToken = () => {
  const { data } = router.currentRoute.value.query;
  if (!data) {
    router.push({
      name: 'Home',
    });
  }

  store.verify(data);
  store.decodeUser(data);
  userId.value = store.id;
  name.value = store.name;
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/_login.scss';
</style>

<template>
  <div>
    <AccountStepper :current-step="1" />

    <div class="register__container">
      <div class="register__container-notify-top">
        <p>{{ $t('register.form.agree-terms') }}</p>
      </div>

      <Form @submit="onSubmit">
        <div class="register__form text-center">
          <p class="terms-text">
            {{ $t('register.form.terms-account') }}
          </p>

          <div class="register__forms-terms">
            <input v-model="isAgreeTerm" type="checkbox" />
            {{ $t('register.form.terms-text') }}
            <a
              class="register__form-terms__link"
              href="https://sellia.com.mx/aviso-de-privacidad"
              target="_blank"
              rel="noopener noreferrer"
              >{{ $t('register.form.terms-link') }}</a
            >
            *
          </div>

          <button type="submit" :disabled="!isAgreeTerm">
            {{ $t('register.config-billing.next') }}
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import AccountStepper from '@/components/account/AccountStepper.vue';
import router from '@/router';
import { useUserStore } from '@/stores/user';

const store = useUserStore();
const isAgreeTerm = ref(false);

const onSubmit = async () => {
  router.push({ name: 'AccountLinked' });
};

onMounted(() => {
  const { token, refresh } = router.currentRoute.value.query;

  store.resetData();
  store.verify(token, refresh);
});
</script>

<style lang="scss" scoped>
@import '~styles/views/create-account';
</style>

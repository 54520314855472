<template>
  <background>
    <div class="page">
      <logo v-show="showLogo && !redirectPage" />
      <transition name="fade">
        <div class="login" v-show="show" style="animation-duration: 0.8s">
          <Form @submit="onSubmit" :validation-schema="schema">
            <div class="login-form">
              <span class="login__recover">{{
                $t('login.recover-access')
              }}</span>
              <div class="login__subtitle">
                <div>
                  {{ $t('login.send-email') }}
                </div>
                <div>{{ $t('login.recover-link') }}</div>
              </div>
              <div class="login__recover-group">
                <Field
                  name="email"
                  class="login__input-container"
                  v-slot="{ field, errors }"
                >
                  <label class="login__input-label">{{
                    $t('login.email')
                  }}</label>
                  <div class="login__input-box">
                    <email class="login__icon" />
                    <input
                      id="email"
                      type="email"
                      class="login__input"
                      :class="errors[0] && 'error'"
                      placeholder="example@sellia.com"
                      v-bind="field"
                    />
                  </div>
                  <transition name="fade">
                    <span v-if="errors[0]" class="login__input-error">{{
                      errors[0]
                    }}</span>
                  </transition>
                  <transition name="fade">
                    <span v-if="errorMsg" class="login__input-error">{{
                      errorMsg
                    }}</span>
                  </transition>
                </Field>
              </div>

              <button type="submit" class="login__button" :disabled="isLoading">
                Restablecer
              </button>
              <div class="login__forgot-center">
                <router-link to="/" class="login__forgot-link">
                  {{ $t('login.back-to-home') }}
                </router-link>
              </div>
            </div>
          </Form>
        </div>
      </transition>
    </div>
  </background>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Field, Form } from 'vee-validate';
import Email from '@/assets/images/email.svg';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { datadogRum } from '@datadog/browser-rum';
import Logo from './Logo.vue';
import Background from './Background.vue';

const store = useUserStore();
const { t } = useI18n();
const schema = { email: 'required|email' };
const show = ref(true);
const showLogo = ref(true);
const errorMsg = ref('');
const isLoading = ref(false);

onMounted(() => {
  setTimeout(() => {
    show.value = true;
    showLogo.value = true;
  }, 300);
});

const onSubmit = async (values) => {
  isLoading.value = true;
  errorMsg.value = '';

  try {
    const res = await store.forgotPassword({
      email: values.email,
    });
    if (res.status === 200) {
      router.push({
        name: 'Home',
        query: {
          message: t('login.recover-password-success'),
        },
      });
    }
  } catch (e) {
    console.error(e);
    if (e?.response?.status === 401) {
      errorMsg.value = t('login.recover-password-user-not-fount');
      return;
    }
    errorMsg.value = t('default-error');
    datadogRum.addError(e);
  } finally {
    isLoading.value = false;
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/_login.scss';
</style>

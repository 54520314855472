<template>
  <div>
    <div class="verified__container">
      <div class="verified__container__icon-container">
        <CheckCircle />
      </div>
      <p>
        {{ $t('register.form.team-linked-account') }}
      </p>

      <button type="button" @click="login">
        {{ $t('register.team-created.sign-in') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import CheckCircle from '@/assets/images/check-circle.svg';

const store = useUserStore();

const login = () => {
  router.push('/');
};

onMounted(() => {
  const { token } = router.currentRoute.value.query;

  store.resetData();
  store.verify(token);
});
</script>

<style lang="scss" scoped>
@import '~styles/views/verify-account';
</style>

<template>
  <div
    class="tooltip-container"
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
  >
    <slot></slot>
    <transition name="fade">
      <div v-if="isVisible" :class="['tooltip', `position-${position}`]">
        {{ content }}
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const isVisible = ref(false);

const showTooltip = () => {
  isVisible.value = true;
};

const hideTooltip = () => {
  isVisible.value = false;
};

defineProps(['content', 'position']);
</script>

<style scoped lang="scss">
.tooltip-container {
  position: relative;

  .tooltip {
    background-color: #333;
    border-radius: 4px;
    color: #fff;
    display: none;
    font-size: 12px;
    font-weight: normal;
    position: absolute;
    padding: 8px;
    opacity: 0;
    transition: opacity 0.3s;
    width: max-content;
    max-width: 200px;

    &::after {
      content: '';
      position: absolute;
      border-width: 8px;
      border-style: solid;
    }

    &.position-top {
      top: -8px;
      left: 50%;
      transform: translateX(-50%) translateY(-100%);
      &::after {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-color: #333 transparent transparent transparent;
      }
    }

    &.position-bottom {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      &::after {
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        border-color: transparent transparent #333 transparent;
      }
    }

    &.position-left {
      top: 20%;
      left: -10px;
      transform: translateX(-100%);
      &::after {
        top: 10px;
        left: calc(100% + 7px);
        transform: translateX(-50%) rotate(90deg);
        border-color: transparent transparent #333 transparent;
      }
    }
  }

  &:hover .tooltip {
    display: block;
    opacity: 1;
  }
}
</style>

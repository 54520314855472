import axios from 'axios';
import { useUserStore } from '@/stores/user';

const baseUrl = import.meta.env.VITE_API_CORE_URL;
const API = axios.create({ baseURL: `${baseUrl}/api/v1` });

export const getPromoCode = ({ code, total, currency, tier }) => {
  const store = useUserStore();

  return API.get(
    `/coupon/${code}?total=${total}&currency=${currency}&tier=${tier}`,
    {
      headers: {
        Authorization: `Bearer ${store.$state.token}`,
      },
    }
  );
};

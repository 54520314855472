<template>
  <div class="register__container">
    <p>
      {{ $t('register.register-team.invited-as') }}
      <b class="text-capitalize">{{ getRoleName() }}</b>
      {{ $t('register.register-team.in') }}
      <b>Sellia</b>
    </p>

    <p>{{ $t('register.register-team.register') }}</p>

    <Form @submit="onSubmit" :validation-schema="schema">
      <div class="register__form">
        <div class="register__form-group">
          <label for="name">{{ $t('register.form.fullName') }} *</label>
          <Field name="name" v-slot="{ errorMessage, field }" v-model="name">
            <input
              id="name"
              type="text"
              :class="{ error: errorMessage }"
              v-bind="field"
            />
            <transition name="fade">
              <span class="register__form-group-error" v-if="errorMessage">{{
                errorMessage
              }}</span>
            </transition>
          </Field>
        </div>
        <div class="register__form-group">
          <label for="password">{{ $t('register.form.password') }} *</label>
          <Field name="password" v-slot="{ errorMessage, field }">
            <div class="relative">
              <button
                v-if="field.value?.length > 0"
                type="button"
                class="btn-password"
                tabindex="-1"
                @click.prevent.stop="showPassword = !showPassword"
              >
                <eye v-if="!showPassword" />
                <eye-off v-else />
              </button>
              <input
                id="password"
                :type="showPassword ? 'text' : 'password'"
                :class="{ error: errorMessage }"
                v-bind="field"
              />
            </div>
            <transition name="fade">
              <span class="register__form-group-error" v-if="errorMessage">{{
                errorMessage
              }}</span>
            </transition>
          </Field>
        </div>
        <div class="register__form-group">
          <label for="passwordConfirmation"
            >{{ $t('register.form.confirm-password') }} *</label
          >
          <Field name="passwordConfirmation" v-slot="{ errorMessage, field }">
            <div class="relative">
              <button
                v-if="field.value?.length > 0"
                type="button"
                class="btn-password"
                tabindex="-1"
                @click.prevent.stop="showPasswordConfirm = !showPasswordConfirm"
              >
                <eye v-if="!showPasswordConfirm" />
                <eye-off v-else />
              </button>
              <input
                id="passwordConfirmation"
                :type="showPasswordConfirm ? 'text' : 'password'"
                :class="{ error: errorMessage }"
                v-bind="field"
              />
            </div>
            <transition name="fade">
              <span class="register__form-group-error" v-if="errorMessage">{{
                errorMessage
              }}</span>
            </transition>
          </Field>
        </div>

        <div class="register__forms-terms">
          <input v-model="isAgreeTerm" type="checkbox" />
          {{ $t('register.form.terms-text') }}
          <a
            class="register__form-terms__link"
            href="https://sellia.com.mx/aviso-de-privacidad"
            target="_blank"
            rel="noopener noreferrer"
            >{{ $t('register.form.terms-link') }}</a
          >
          *
        </div>

        <button type="submit" :disabled="!isAgreeTerm || isLoading">
          {{ $t('register.form.create-account') }}
        </button>

        <button type="button" class="button-google" @click="loginGoogle">
          <GoogleIcon /> {{ $t('register.form.singup-google') }}
        </button>

        <p v-if="errorMsg.length" class="register__form-default-error">
          {{ errorMsg }}
        </p>
      </div>
    </Form>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import router from '@/router';
import { Field, Form } from 'vee-validate';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { getRoles } from '@/services/user';
import GoogleIcon from '@/assets/images/google.svg';
import { signupTeam } from '@/services/auth';
import EyeOff from '@/assets/images/eye-off.svg';
import Eye from '@/assets/images/eye.svg';
import { datadogRum } from '@datadog/browser-rum';

const redirectUri = import.meta.env.VITE_GOOGLE_REDIRECT_URI;
const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const googleAuthUri = import.meta.env.VITE_GOOGLE_AUTH_URI;
const store = useUserStore();
const { t } = useI18n();
const isAgreeTerm = ref(false);
const isLoading = ref(false);
const errorMsg = ref('');
const name = ref('');
const userId = ref('');
const roleList = ref([]);
const showPassword = ref(false);
const showPasswordConfirm = ref(false);

const schema = {
  name: 'required|min:5',
  password: 'required|min:8|minUppercase:1|minLowercase:1|minDigits:1',
  passwordConfirmation: 'required|confirmed:@password',
};

const onSubmit = async (values) => {
  isLoading.value = true;
  errorMsg.value = '';

  try {
    await store.registerTeam(userId.value, {
      name: values.name,
      password: values.password,
    });

    await store.resetData();
    router.push({ name: 'AccountCreated' });
  } catch (e) {
    errorMsg.value = t('default-error');
    datadogRum.addError(e);
  } finally {
    isLoading.value = false;
  }
};

const loginGoogle = () => {
  window.location.href = `${googleAuthUri}?client_id=${clientId}&redirect_uri=${redirectUri}/create-account/team&response_type=code&scope=profile email`;
};

const getToken = async () => {
  try {
    const { data } = router.currentRoute.value.query;

    await store.refreshToken(data);
    userId.value = store.id;
    name.value = store.name;
  } catch (error) {
    errorMsg.value = t('register.register-team.wrong-link');
    datadogRum.addError(error);
  }
};

const getRoleName = () => {
  const role = roleList.value.find((role) => role.name === store.role);

  return role?.display_name || store.role;
};

const getRoleList = async () => {
  const { data } = await getRoles();

  roleList.value = data;
};

const signupWithGoogle = async (code) => {
  try {
    const response = await signupTeam({ code });

    if (response.data.token) {
      router.push(`/create-account/linkedTeam?token=${response.data.token}`);
    }
  } catch (error) {
    if (error.response?.data?.message) {
      errorMsg.value = error.response.data.message;
      return;
    }
    datadogRum.addError(error);
    errorMsg.value = t('default-error');
  }
};

onMounted(() => {
  if (router.currentRoute?.value?.query?.code) {
    signupWithGoogle(router.currentRoute.value.query.code);
    return;
  }

  store.resetData();
  getToken();
  getRoleList();
});
</script>

<style lang="scss" scoped>
@import '~styles/views/create-account';
</style>

<template>
  <background>
    <div class="page">
      <logo v-show="showLogo" />
      <transition name="fade">
        <div
          class="login"
          v-show="show"
          style="animation-duration: 0.8s; max-width: 450px"
        >
          <Form @submit="onSubmit" :validation-schema="schema">
            <div class="login-form">
              <span class="login__link">{{
                $t('login.associate-google.title')
              }}</span>
              <div v-if="!googleCode" class="login__link-subtitle">
                {{ $t('login.associate-google.subtitle') }}
              </div>
              <div v-if="googleCode" class="login__link-subtitle">
                {{ $t('login.associate-google.verify-identity') }}
              </div>
              <div class="login__associate-group" v-if="googleCode">
                <div>
                  <Field
                    name="email"
                    class="login__input-container"
                    v-slot="{ field, errors }"
                  >
                    <label class="login__input-label">{{
                      $t('login.email')
                    }}</label>
                    <div class="login__input-box">
                      <email class="login__icon" />
                      <input
                        id="email"
                        type="email"
                        class="login__input"
                        :class="errors[0] && 'error'"
                        v-bind="field"
                        :value="emailInput"
                      />
                    </div>
                    <p class="text-white">
                      {{ $t('login.associate-google.email-associated') }}
                    </p>
                    <transition name="fade">
                      <span v-if="errors[0]" class="login__input-error">{{
                        errors[0]
                      }}</span>
                    </transition>
                  </Field>
                </div>

                <div>
                  <Field
                    name="password"
                    v-slot="{ field, errors }"
                    class="login__input-container"
                  >
                    <label class="login__input-label">
                      {{ $t('login.password') }}
                    </label>

                    <div class="login__input-password">
                      <button
                        type="button"
                        class="login__input-password-icon"
                        v-if="field.value?.length > 0"
                        @click.prevent.stop="toggleShow"
                      >
                        <eye v-if="showPassword" />
                        <eye-off v-else />
                      </button>
                      <div class="login__input-box">
                        <lock class="login__icon" />
                        <input
                          v-bind="field"
                          ref="password"
                          :type="passwordInput"
                          class="login__input"
                          data-test-id="passwordTextInput"
                          :class="errors[0] && 'error'"
                          placeholder="********"
                        />
                      </div>
                    </div>
                    <transition name="fade">
                      <span
                        v-if="errors[0]"
                        class="login__input-error"
                        data-test-id="passValidationErrorLabel"
                        >{{ errors[0] }}</span
                      >
                    </transition>
                  </Field>
                </div>
              </div>

              <p
                v-show="errorMsg"
                class="login__forgot-alert"
                data-test-id="invalidUserLabel"
              >
                {{ errorMsg }}
              </p>

              <template v-if="!googleCode">
                <button
                  type="button"
                  class="login__button associate"
                  @click="getGoogleCode"
                >
                  {{ $t('login.associate-google.associate-account') }}
                </button>

                <div class="login__go-home-center">
                  <router-link to="/" class="login__go-home-link">
                    {{ $t('login.back-to-home') }}
                  </router-link>
                </div>
              </template>

              <template v-if="googleCode">
                <button
                  type="submit"
                  class="login__button"
                  :disabled="isLoading"
                >
                  {{ $t('login.associate-google.confirm') }}
                </button>

                <div
                  class="login__go-home-center text-white"
                  @click="$router.go(-1)"
                >
                  <span class="login__go-home-link">{{
                    $t('login.associate-google.cancel')
                  }}</span>
                </div>
              </template>
            </div>
          </Form>
        </div>
      </transition>
    </div>
  </background>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Field, Form } from 'vee-validate';
import Email from '@/assets/images/email.svg';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import Lock from '@/assets/images/lock.svg';
import Eye from '@/assets/images/eye.svg';
import EyeOff from '@/assets/images/eye-off.svg';
import { link } from '@/services/auth';
import { datadogRum } from '@datadog/browser-rum';
import Logo from './Logo.vue';
import Background from './Background.vue';

const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const redirectUri = import.meta.env.VITE_GOOGLE_REDIRECT_URI;
const googleAuthUri = import.meta.env.VITE_GOOGLE_AUTH_URI;
const store = useUserStore();
const { t } = useI18n();
const schema = { password: 'required' };
const show = ref(true);
const showLogo = ref(true);
const showPassword = ref(true);
const errorMsg = ref('');
const isLoading = ref(false);
const emailInput = ref('');
const passwordInput = ref('password');
const googleCode = ref(null);

const toggleShow = () => {
  showPassword.value = !showPassword.value;
  passwordInput.value = showPassword.value ? 'password' : 'text';
};

const getGoogleCode = () => {
  window.location.href = `${googleAuthUri}?client_id=${clientId}&redirect_uri=${redirectUri}/link-google&response_type=code&scope=profile email`;
};

const onSubmit = async (values) => {
  isLoading.value = true;
  errorMsg.value = '';

  try {
    const response = await link({
      code: googleCode.value,
      email: store.email,
      password: values.password,
    });

    store.setData({
      token: response.data.token,
      forceChangePassword: response.data.forceChangePassword,
    });
    if (store.forceChangePassword) {
      router.push({
        name: 'ChangePassword',
        query: {
          data: store.token,
        },
      });
      return;
    }
    window.location.href = store.getBaseUrl();
  } catch (error) {
    if (error.response?.data?.message) {
      errorMsg.value = error.response.data.message;
      return;
    }

    errorMsg.value = t('default-error');
    datadogRum.addError(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  setTimeout(() => {
    show.value = true;
    showLogo.value = true;
  }, 300);

  const { email, code } = router.currentRoute.value.query;

  if (email) {
    store.setEmail(email);
  }

  if (code) {
    googleCode.value = code;
  }

  if (store.email) {
    emailInput.value = store.email;
  }
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/_login.scss';
</style>

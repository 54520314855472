<template>
  <background>
    <div class="page">
      <logo
        class="logo-sellia"
        v-show="showLogo && !redirectPage"
        data-test-id="selliaLandingLogo"
      />
      <Transition name="fade">
        <div
          class="login no-min-height"
          v-show="show"
          style="animation-duration: 0.8s"
        >
          <div class="login__title" data-test-id="landingWelcomeLabel">
            {{ $t('login.welcome') }}
          </div>
          <Form
            @submit="loginSubmit"
            :validation-schema="schema"
            v-slot="{ meta }"
          >
            <div class="login__form">
              <div class="login__input-group">
                <div>
                  <Field
                    name="email"
                    class="login__input-container"
                    v-slot="{ field, errors }"
                  >
                    <label class="login__input-label">
                      {{ $t('login.email') }}
                    </label>
                    <div class="login__input-box">
                      <email class="login__icon" />
                      <input
                        v-bind="field"
                        type="email"
                        data-test-id="emailTextInput"
                        class="login__input"
                        :class="errors[0] && 'error'"
                        placeholder="example@sellia.com"
                        @keyup.enter="$refs.password.focus()"
                      />
                    </div>
                    <transition name="fade">
                      <span
                        v-if="errors[0]"
                        class="login__input-error"
                        data-test-id="emailValidationErrorLabel"
                        >{{ errors[0] }}</span
                      >
                    </transition>
                  </Field>
                </div>
                <div>
                  <Field
                    name="password"
                    v-slot="{ field, errors }"
                    class="login__input-container"
                  >
                    <label class="login__input-label">
                      {{ $t('login.password') }}
                    </label>

                    <div class="login__input-password">
                      <button
                        type="button"
                        class="login__input-password-icon"
                        v-if="field.value?.length > 0"
                        @click.prevent.stop="toggleShow"
                      >
                        <eye v-if="showPassword" />
                        <eye-off v-else />
                      </button>
                      <div class="login__input-box">
                        <lock class="login__icon" />
                        <input
                          v-bind="field"
                          ref="password"
                          :type="passwordInput"
                          class="login__input"
                          data-test-id="passwordTextInput"
                          :class="errors[0] && 'error'"
                          placeholder="********"
                        />
                      </div>
                    </div>
                    <transition name="fade">
                      <span
                        v-if="errors[0]"
                        class="login__input-error"
                        data-test-id="passValidationErrorLabel"
                        >{{ errors[0] }}</span
                      >
                    </transition>

                    <p
                      v-show="errorMsg"
                      v-html="errorMsg"
                      class="login__forgot-alert"
                      data-test-id="invalidUserLabel"
                    ></p>
                  </Field>
                </div>
              </div>
              <div class="login__forgot" @click="toggleRedirect">
                <router-link
                  to="/forgot-password"
                  class="login__forgot-link"
                  data-test-id="forgotPassLink"
                >
                  {{ $t('login.forgot') }}
                </router-link>
              </div>
              <button
                type="submit"
                ref="clickBtn"
                :disabled="!meta.valid || (isLoading && meta.valid)"
                @click="animateRipple($event)"
                class="login__button"
                data-test-id="loginButton"
                :class="
                  (isLoading && meta.valid && 'disabled') ||
                  (!meta.valid && 'disabled')
                "
              >
                {{ $t('login.log-in') }}

                <transition-group>
                  <span
                    class="button-effect"
                    :ref="`button-effect${i}`"
                    :key="`button-effect${i}`"
                    v-for="(val, i) in activeRipples"
                    :style="{ top: `${val.y}px`, left: `${val.x}px` }"
                    v-on:animationend="rippleEnd(i)"
                  >
                  </span>
                </transition-group>
              </button>

              <div class="line-or-separator">
                <span />
                <span>o</span>
                <span />
              </div>

              <button type="button" class="button-google" @click="loginGoogle">
                <span><GoogleIcon /></span>
                {{ $t('login.login-google') }}
              </button>

              <div class="register-link">
                <p>{{ $t('login.no-account') }}</p>
                <a href="/create-account">{{ $t('login.register-here') }}</a>
              </div>
            </div>
          </Form>
        </div>
      </Transition>
      <Toast ref="toast" />
    </div>
  </background>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { Field, Form } from 'vee-validate';
import Eye from '@/assets/images/eye.svg';
import EyeOff from '@/assets/images/eye-off.svg';
import Email from '@/assets/images/email.svg';
import Lock from '@/assets/images/lock.svg';
import { useUserStore } from '@/stores/user';
import router from '@/router';
import Toast from '@/components/Toast.vue';
import GoogleIcon from '@/assets/images/google.svg';
import { signin } from '@/services/auth';
import { useI18n } from 'vue-i18n';
import { datadogRum } from '@datadog/browser-rum';
import Background from './Background.vue';
import Logo from './Logo.vue';

const store = useUserStore();
const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const redirectUri = import.meta.env.VITE_GOOGLE_REDIRECT_URI;
const googleAuthUri = import.meta.env.VITE_GOOGLE_AUTH_URI;
const { t } = useI18n();
const redirectPage = ref(false);
const show = ref(false);
const showLogo = ref(false);
const showPassword = ref(true);
const ripples = ref([]);
const isLoading = ref(false);
const errorMsg = ref(null);
const clickBtn = ref();
const passwordInput = ref('password');
const toast = ref(null);
const supportPortalUrl = import.meta.env.VITE_SUPPORT_PORTAL_URL;

const schema = {
  email: 'required|email',
  password: 'required|min:2',
};

const toggleShow = () => {
  showPassword.value = !showPassword.value;
  passwordInput.value = showPassword.value ? 'password' : 'text';
};

const loginSubmit = async (values) => {
  isLoading.value = true;
  try {
    await store.login(values);

    if (store.requireFinishAccount) {
      store.setStepAccountURL();
      return;
    }

    if (store.forceChangePassword) {
      router.push({
        name: 'ChangePassword',
        query: {
          data: store.token,
        },
      });
      return;
    }
    window.location.href = store.getBaseUrl();
  } catch (e) {
    if (e.response && e.response.status === 401) {
      const { message, name } = e.response.data;

      if (name === 'companyCanceled') {
        errorMsg.value = `${message}
        <a style="color: #d0242b !important;" href="${supportPortalUrl}">${t(
          'common.here'
        )}</a>`;
        return;
      }

      errorMsg.value = message;
    } else {
      datadogRum.addError(e);
    }
  } finally {
    isLoading.value = false;
  }
};

const loginGoogle = () => {
  window.location.href = `${googleAuthUri}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=profile email`;
};

const exchangeGoogleToken = async (code) => {
  try {
    const { data } = await signin({ code });

    store.setData(data);

    if (data.redirect) {
      if (data.redirect === 'payment') {
        store.setStepCreatingAccount(4);
      } else if (data.redirect === 'company') {
        store.setStepCreatingAccount(3);
      }

      store.setStepAccountURL();
      return;
    }

    if (!data.token) {
      router.push({
        name: 'linkGoogleAccount',
        query: {
          email: data.email,
        },
      });

      return;
    }

    store.setData(data);
    window.location.href = store.getBaseUrl();
  } catch (error) {
    if (error.response?.data?.message) {
      errorMsg.value = error.response.data.message;
      return;
    }

    errorMsg.value = t('default-error');
    datadogRum.addError(error);
  }
};

const animateRipple = (e) => {
  const pos = clickBtn.value.getBoundingClientRect();
  ripples.value.push({
    x: e.clientX - pos.left,
    y: e.clientY - pos.top,
    show: true,
  });
};

const rippleEnd = (i) => {
  ripples.value[i].show = false;
};

const activeRipples = computed(() => ripples.value.filter((r) => r.show));

onMounted(() => {
  if (store.requireFinishAccount) {
    store.setStepAccountURL();
    return;
  }

  if (store.isValidToken()) {
    window.location.href = store.getBaseUrl();
  }

  setTimeout(() => {
    show.value = true;
    showLogo.value = true;

    const { message } = router.currentRoute.value.query;
    if (message) {
      toast.value.showToast(message);
    }
  }, 300);

  const { error, code } = router.currentRoute.value.query;

  if (code) {
    exchangeGoogleToken(code);
  }

  if (error) {
    errorMsg.value = error;
  }
});
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/_login.scss';
</style>

import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Logout from '@/views/Logout.vue';
import RestorePassword from '@/views/RestorePassword.vue';
import PageNotFound from '@/components/PageNotFound.vue';
import CreateAccount from '@/views/account/CreateAccount.vue';
import AccountEmailSend from '@/views/account/AccountEmailSend.vue';
import AccountLayout from '@/components/account/AccountLayout.vue';
import AccountVerify from '@/views/account/AccountVerify.vue';
import AccountSpace from '@/views/account/AccountSpace.vue';
import AccountPayment from '@/views/account/AccountPayment.vue';
import AccountBilling from '@/views/account/AccountBilling.vue';
import AccountInvite from '@/views/account/AccountInvite.vue';
import CreateAccountTeam from '@/views/account/CreateAccountTeam.vue';
import AccountCreated from '@/views/account/AccountCreated.vue';
import AccountPaid from '@/views/account/AccountPaid.vue';
import ChangePassword from '@/views/ChangePassword.vue';
import AccountInvited from '@/views/account/AccountInvited.vue';
import AccountInvoiced from '@/views/account/AccountInvoiced.vue';
import AccountTerms from '@/views/account/AccountTerms.vue';
import AccountLinked from '@/views/account/AccountLinked.vue';
import AccountLinkedTeam from '@/views/account/AccountLinkedTeam.vue';
import LinkGoogleAccount from '@/views/LinkGoogleAccount.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/create-account/confirm',
      name: 'EmailSend',
      component: AccountEmailSend,
    },
    {
      path: '/create-account',
      name: 'CreateAccount',
      component: CreateAccount,
    },
    {
      path: '/create-account',
      name: 'ConfigAccount',
      component: AccountLayout,
      children: [
        {
          path: 'verify',
          name: 'Verify',
          component: AccountVerify,
        },
        {
          path: 'space',
          name: 'ConfigSpace',
          component: AccountSpace,
        },
        {
          path: 'payment',
          name: 'ConfigPayment',
          component: AccountPayment,
        },
        {
          path: 'paid',
          name: 'ConfigPaid',
          component: AccountPaid,
        },
        {
          path: 'billing',
          name: 'ConfigBilling',
          component: AccountBilling,
        },
        {
          path: 'invite',
          name: 'ConfigInvite',
          component: AccountInvite,
        },
        {
          path: 'team',
          name: 'RegisterTeam',
          component: CreateAccountTeam,
        },
        {
          path: 'created',
          name: 'AccountCreated',
          component: AccountCreated,
        },
        {
          path: 'invited',
          name: 'AccountInvited',
          component: AccountInvited,
        },
        {
          path: 'invoiced',
          name: 'AccountInvoiced',
          component: AccountInvoiced,
        },
        {
          path: 'terms',
          name: 'AccountTerms',
          component: AccountTerms,
        },
        {
          path: 'linked',
          name: 'AccountLinked',
          component: AccountLinked,
        },
        {
          path: 'linkedTeam',
          name: 'AccountLinkedTeam',
          component: AccountLinkedTeam,
        },
      ],
    },
    {
      path: '/logout',
      name: 'Logout',
      component: Logout,
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: RestorePassword,
    },
    {
      path: '/link-google',
      name: 'linkGoogleAccount',
      component: LinkGoogleAccount,
    },
    {
      path: '/change-password',
      name: 'ChangePassword',
      component: ChangePassword,
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: PageNotFound,
    },
  ],
});

export default router;

/* eslint-disable no-param-reassign */
import moment from 'moment';
import 'moment/dist/locale/es';

moment.locale('es');

export default {
  install: (app) => {
    app.config.globalProperties.$moment = moment;
  },
};

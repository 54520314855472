<template>
  <Toast ref="toast" @refreshPage="refreshPage" />
  <router-view></router-view>
</template>

<script setup>
import { ref } from 'vue';
import { RouterView } from 'vue-router';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import Toast from '@/components/Toast.vue';

const toast = ref(null);
const refreshPage = () => {
  updateServiceWorker();
};

const { updateServiceWorker } = useRegisterSW({
  onNeedRefresh() {
    toast.value.showToastWithCounter();
  },
});
</script>

<style lang="scss">
@import '~styles/app';
@import '@/assets/styles/views/_login.scss';
</style>

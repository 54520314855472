<template>
  <AccountLayout>
    <AccountStepper :current-step="1" />
    <AccountRegisterForm v-if="!isRegistered" @onFinish="registerUser" />
  </AccountLayout>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import AccountRegisterForm from '@/components/account/AccountRegisterForm.vue';
import AccountLayout from '@/components/account/AccountLayout.vue';
import AccountStepper from '@/components/account/AccountStepper.vue';

const store = useUserStore();
const isRegistered = ref(false);

onMounted(() => {
  if (!store.$state.isCreatingAccount) {
    const { query } = router.currentRoute.value;

    if (query.plan) {
      store.changePlan(query.plan.toUpperCase());
    }

    store.resetData();
    return;
  }

  if (store.$state.isCreatingAccount && store.$state.stepCreatingAccount > 1) {
    store.setStepAccountURL();
  }
});

const registerUser = async (email) => {
  router.push({
    name: 'EmailSend',
    query: { email },
  });
};
</script>

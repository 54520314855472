<template>
   <transition name="fade">
    <div v-if="visible" class="toast" :class="{ update: toastType }">
      {{ message }}
      <button type="button" @click="hideToast">{{ messageButton }}</button>
    </div>
   </transition>
</template>

<script setup>
import { ref } from 'vue';
import { defineEmits } from 'vue'
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['refreshPage'])
const { t } = useI18n();
const toastType = ref(null);
const visible = ref(false);
const message = ref('');
const messageButton =  ref('')

function showToast(msg) {
  messageButton.value =  t('login.close');
  toastType.value = false;
  message.value = msg;
  visible.value = true;
  setTimeout(() => {
    visible.value = false;
  }, 8000);
}

function showToastWithCounter() {
  toastType.value = true;
  message.value = t('login.message-update')
  visible.value = true;
  messageButton.value =  t('login.accept');
  
  let cont = 10;
  const clear = setInterval(() => {
    if (cont === 0) {
      clearInterval(clear);
      hideToast();
    }
    cont -= 1;
  },10000)
}

function hideToast() {
  visible.value = false;
  emit('refreshPage');
}

defineExpose({ showToast, showToastWithCounter });
</script>

<style>
.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1000;
  border-radius: 15px;
}

.toast p {
  margin: 0;
  color: #333;
}

.toast button {
  background-color: #007bff;
  border: none;
  padding: 5px 10px;
  color: white;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div class="register__container">
    <p v-html="$t('register.welcome')" />

    <Form @submit="onSubmit" :validation-schema="schema">
      <div class="register__form">
        <div class="register__form-group">
          <label for="email">{{ $t('register.form.email') }} *</label>
          <Field name="email" v-slot="{ errorMessage, field }">
            <input
              id="email"
              data-test-id="emailText"
              type="email"
              :class="{ error: errorMessage }"
              v-bind="field"
            />
            <transition name="fade">
              <span
                data-test-id="emailLabelError"
                class="register__form-group-error"
                v-if="errorMessage"
                >{{ errorMessage }}</span
              >
            </transition>
          </Field>
        </div>
        <div class="register__form-group">
          <label for="name">{{ $t('register.form.fullName') }} *</label>
          <Field name="name" v-slot="{ errorMessage, field }">
            <input
              id="name"
              data-test-id="fullNameText"
              type="text"
              :class="{ error: errorMessage }"
              v-bind="field"
            />
            <transition name="fade">
              <span
                data-test-id="nameLabelError"
                class="register__form-group-error"
                v-if="errorMessage"
                >{{ errorMessage }}</span
              >
            </transition>
          </Field>
        </div>
        <div class="register__form-group">
          <label for="password">{{ $t('register.form.password') }} *</label>
          <Field name="password" v-slot="{ errorMessage, field }">
            <div class="relative">
              <input
                id="password"
                ref="password"
                data-test-id="passText"
                :type="showPassword ? 'text' : 'password'"
                :class="{ error: errorMessage }"
                v-bind="field"
              />
              <button
                v-if="field.value?.length > 0"
                type="button"
                class="btn-password"
                tabindex="-1"
                @click.prevent.stop="showPassword = !showPassword"
              >
                <eye v-if="!showPassword" />
                <eye-off v-else />
              </button>
            </div>
            <transition name="fade">
              <span
                data-test-id="passLabelError"
                class="register__form-group-error"
                v-if="errorMessage"
                >{{ errorMessage }}</span
              >
            </transition>
          </Field>
        </div>
        <div class="register__form-group">
          <label for="passwordConfirmation"
            >{{ $t('register.form.confirm-password') }} *</label
          >
          <Field name="passwordConfirmation" v-slot="{ errorMessage, field }">
            <div class="relative">
              <input
                id="passwordConfirmation"
                data-test-id="passConfirmText"
                ref="confirmPassword"
                :type="showConfirmPassword ? 'text' : 'password'"
                :class="{ error: errorMessage }"
                v-bind="field"
              />
              <button
                v-if="field.value?.length > 0"
                type="button"
                class="btn-password"
                tabindex="-1"
                @click.prevent.stop="showConfirmPassword = !showConfirmPassword"
              >
                <eye v-if="!showConfirmPassword" />
                <eye-off v-else />
              </button>
            </div>
            <transition name="fade">
              <span
                data-test-id="confirmPassLabelError"
                class="register__form-group-error"
                v-if="errorMessage"
                >{{ errorMessage }}</span
              >
            </transition>
          </Field>
        </div>

        <div class="register__forms-terms">
          <input
            data-test-id="agreeTermsCheck"
            v-model="isAgreeTerm"
            type="checkbox"
          />
          {{ $t('register.form.terms-text') }}
          <a
            data-test-id="agreeTermsLink"
            class="register__form-terms__link"
            href="https://sellia.com.mx/aviso-de-privacidad"
            target="_blank"
            rel="noopener noreferrer"
            >{{ $t('register.form.terms-link') }}</a
          >
          *
        </div>

        <button
          data-test-id="createAccountButton"
          type="submit"
          :disabled="!isAgreeTerm || isLoading"
        >
          {{ $t('register.form.create-account') }}
        </button>

        <button type="button" class="button-google" @click="signupGoogle">
          <GoogleIcon /> {{ $t('register.form.singup-google') }}
        </button>

        <p
          data-test-id="submitErrorLabel"
          v-if="errorMsg.length"
          class="register__form-default-error"
        >
          {{ errorMsg }}
        </p>
      </div>
    </Form>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Field, Form } from 'vee-validate';
import { useUserStore } from '@/stores/user';
import router from '@/router';
import { signup } from '@/services/auth';
import Eye from '@/assets/images/eye.svg';
import EyeOff from '@/assets/images/eye-off.svg';
import GoogleIcon from '@/assets/images/google.svg';
import { datadogRum } from '@datadog/browser-rum';

const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const redirectUri = import.meta.env.VITE_GOOGLE_REDIRECT_URI;
const googleAuthUri = import.meta.env.VITE_GOOGLE_AUTH_URI;
const store = useUserStore();
const isAgreeTerm = ref(false);
const isLoading = ref(false);
const showPassword = ref(false);
const showConfirmPassword = ref(false);
const errorMsg = ref('');
const { t } = useI18n();
const emit = defineEmits(['onFinish']);

const schema = {
  email: 'required|email',
  name: 'required|min:5',
  password: 'required|min:8|minUppercase:1|minLowercase:1|minDigits:1',
  passwordConfirmation: 'required|confirmed:@password',
};

const onSubmit = async (values) => {
  isLoading.value = true;

  try {
    await store.register({
      email: values.email,
      name: values.name,
      password: values.password,
    });

    emit('onFinish', values.email);
  } catch (error) {
    if (error.response?.data?.errors?.length) {
      errorMsg.value = error.response.data.errors[0].message;
      return;
    }

    errorMsg.value = t('default-error');
    datadogRum.addError(error);
  } finally {
    isLoading.value = false;
  }
};

const signupGoogle = () => {
  window.location.href = `${googleAuthUri}?client_id=${clientId}&redirect_uri=${redirectUri}/create-account&response_type=code&scope=profile email`;
};

const signupWithGoogle = async (code) => {
  try {
    const {
      data: { token, refresh },
    } = await signup({ code });

    router.push({
      name: 'AccountTerms',
      query: {
        token,
        refresh,
      },
    });
  } catch (error) {
    if (error.response?.data?.message) {
      errorMsg.value = error.response.data.message;
      return;
    }

    errorMsg.value = t('default-error');
    datadogRum.addError(error);
  }
};

onMounted(() => {
  const { code, error } = router.currentRoute.value.query;

  if (code) {
    signupWithGoogle(code);
  }

  if (error) {
    errorMsg.value = error;
  }
});
</script>

<style lang="scss" scoped>
@import '~styles/views/create-account';
</style>

<template>
  <div>
    <AccountStepper :current-step="3" />

    <div class="verified__container">
      <div class="verified__container__icon-container">
        <CheckCircle />
      </div>
      <p v-html="$t('register.config-payment.payment-processed')" />

      <button
        type="button"
        @click="nextStep"
        v-html="$t('register.config-payment.next')"
      />
    </div>
  </div>
</template>

<script setup>
import router from '@/router';
import CheckCircle from '@/assets/images/check-circle.svg';
import AccountStepper from '@/components/account/AccountStepper.vue';
import { onMounted } from 'vue';
import { useUserStore } from '@/stores/user';

const store = useUserStore();

const nextStep = async () => {
  store.setStepCreatingAccount(7);
  router.push({ name: 'ConfigInvite' });
};

onMounted(() => {
  if (store.$state.stepCreatingAccount !== 6) {
    store.setStepAccountURL();
  }
});
</script>

<style lang="scss" scoped>
@import '~styles/views/verify-account';
</style>
